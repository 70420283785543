<template>
  <v-dialog v-model="visible" width="auto">
    <v-card color="primary" v-if="loading">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-container v-else class="modal">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <h3>Load template: {{ loadData.title }}</h3>
          </v-col>
          <v-col cols="12">
            <!--Firewall settings-->
            <div class="section">
              <small class="section-title">Firewall settings</small>
              <!--VENDOR-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Vendor</label
                  >
                  <v-select
                    v-model="vendor"
                    :items="vendors"
                    :rules="vendorRules"
                    disabled
                    item-text="name"
                    item-value="id"
                    label="Vendor"
                    placeholder="Vendor"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END VENDOR-->

              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-radio-group v-model="fwType" row class="mt-2">
                    <v-radio
                      label="Firewall"
                      value="firewall"
                      selected
                      @click="fwType = 'firewall'"
                    />
                    <v-radio
                      label="Firewall group"
                      value="group"
                      @click="fwType = 'group'"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <!--FIREWALLS-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
                v-if="fwType === 'firewall'"
              >
                <v-row class="mt-0 mx-0">
                  <v-col cols="12" class="pb-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Firewall</label
                    >
                    <p class="mb-0">
                      <label class="font-roboto-condensed-regular font-size-12"
                        >Expected config will be read from the first
                        device</label
                      >
                    </p>

                    <v-autocomplete
                      v-model="firewall"
                      :items="firewalls"
                      :rules="firewallRules"
                      item-text="name"
                      item-value="id"
                      placeholder="Firewall"
                      multiple
                      outlined
                      @change="checkLoading = false"
                      class="auditor-group"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </transition>
              <!--END FIREWALLS-->

              <!--FIREWALL GROUPS-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
                v-if="fwType === 'group'"
              >
                <v-row class="mt-0 mx-0">
                  <v-col cols="12" class="pb-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Firewall Group</label
                    >
                    <p class="mb-0">
                      <label class="font-roboto-condensed-regular font-size-12"
                        >Expected config will be read from the first
                        device</label
                      >
                    </p>
                    <v-autocomplete
                      v-model="firewallGroup"
                      :items="firewallGroups"
                      :rules="firewallGroupsRules"
                      item-text="name"
                      item-value="id"
                      placeholder="Firewall group"
                      multiple
                      outlined
                      @change="checkLoading = false"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </transition>
              <!--FIREWALL GROUPS-->

              <!--CALL-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Call</label
                  >
                  <v-select
                    v-model="apicall"
                    :items="apicalls"
                    :rules="apicallRules"
                    item-text="name"
                    item-value="id"
                    label="Call"
                    placeholder="Call"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END CALL-->
            </div>

            <!--Variable settings-->
            <div class="section" v-if="variables.length">
              <small class="section-title">Variable settings</small>
              <!--Variables-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <div v-for="(variable, index) in variables" :key="index">
                    <label class="font-roboto-condensed-regular font-size-15">{{
                      variable.name
                    }}</label>
                    <v-text-field
                      v-model="variable.value"
                      :label="'Enter ' + variable.name"
                      :rules="[(v) => !!v || 'Required field']"
                      solo
                      dense
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!--END Variables-->
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="saving"
              v-permission="['write auditorintegrity']"
              rounded
              color="primary"
              class="bottom-btn ml-2 font-ubuntu-bold"
              @click="validate"
            >
              Load
              <template
              v-if="!saving">
                <v-icon class="ml-2 " x-small>fas fa-download</v-icon>
              </template>
              <template
              v-else
              >
                <v-icon
                  color="primary" class="ml-2" x-small>
                  fas fa-spinner fa-spin
                </v-icon>
              </template>

            </v-btn>
            <v-btn
              :disabled="saving"
              rounded
              outlined
              class="text-capitalize bottom-btn font-ubuntu-bold"
              @click="onClose"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-dialog>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import { getFirewallListByVendor } from "@/api/settings/firewall";
import { Errors } from "@/utils/error";
import { getFirewallGroupListByVendor } from "@/api/settings/firewallGroup";
import { getTemplateVariables, loadTemplate } from "@/api/auditor/template";
import { getIntegrityCalls } from "@/api/settings/apicall";

export default {
  name: "loadTemplate",
  directives: { permission },
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    loadData: {
      show: {
        type: Object,
        default: function () {
          return undefined;
        },
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.visible = newValue;
      },
    },
    visible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.onClose();
        }
      },
    },
  },
  data() {
    return {
      visible: false,
      firewallsLoaded: false,
      fwGroupsLoaded: false,
      variablesLoaded: false,
      callsLoaded: false,
      loading: true,
      saving: false,
      valid: true,
      checkLoading: false,

      fwType: "firewall",
      vendor: 1,
      vendors: [{ id: 1, name: "Palo Alto" }],
      vendorRules: [(v) => !!v || "Vendor is required"],
      firewallGroup: [],
      firewallGroups: [],
      firewallRules: [(v) => (!!v && !!v.length) || "Firewalls are required"],
      firewall: [],
      firewalls: [],
      firewallGroupsRules: [
        (v) => (!!v && !!v.length) || "Firewall groups are required",
      ],
      apicall: null,
      apicallRules: [(v) => !!v || "Call is required"],
      apicalls: [],
      variables: [],
    };
  },
  created() {
    this.loadTemplateVariables();
    this.loadFirewalls();
    this.loadFirewallGroups();
    this.loadApicalls();
    this.checkInitialLoading();
  },
  methods: {
    checkInitialLoading() {
      if (
        this.firewallsLoaded &&
        this.fwGroupsLoaded &&
        this.variablesLoaded &&
        this.callsLoaded
      ) {
        this.loading = false;
      } else {
        const self = this;
        setTimeout(() => {
          self.checkInitialLoading();
        }, 500);
      }
    },
    loadTemplateVariables() {
      getTemplateVariables(this.loadData.id)
        .then((response) => {
          this.variables = this.loadData.variables.map((variable) => {
            let value = null;
            for (const item of response.variables) {
              if (item.name === variable) {
                value = item.value;
                break; // exit the loop since we found the matching item
              }
            }
            return { name: variable, value };
          });
          this.variablesLoaded = true;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.load();
      }
    },
    load() {
      this.saving = true;

      const data = {
        vendor: this.vendor,
        firewall: this.firewall,
        fwType: this.fwType,
        firewallGroup: this.firewallGroup,
        apicall: this.apicall,
        variables: this.variables,
      };

      loadTemplate(this.loadData.id, data)
        .then(() => {
          this.saving = false;
          this.$toast.open({
            message: "Auditor Template was successfully loaded!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.onClose();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    loadFirewalls() {
      if (this.vendor) {
        getFirewallListByVendor(this.vendor)
          .then((response) => {
            this.firewalls = response.data;
            this.firewallsLoaded = true;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadFirewallGroups() {
      getFirewallGroupListByVendor(this.vendor)
        .then((response) => {
          this.firewallGroups = response.data;
          this.fwGroupsLoaded = true;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadApicalls() {
      if (this.vendor) {
        getIntegrityCalls(this.vendor)
          .then((response) => {
            this.apicalls = response.data;
            if (!this.apicall) {
              this.apicall = this.apicalls.find(
                (api) =>
                  api.id === 125 || api.name === "PAN-Show-Config-effective"
              ).id;
            }
            this.callsLoaded = true;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark {
  .modal {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  }
  .text-content {
    border: 0.5px solid #ffffff;
  }
}
.theme--light {
  .modal {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  }
  .text-content {
    border: 0.5px solid #1e1e1e;
  }
}
.modal {
  .diff-area {
    padding-bottom: 0.1em;
    color: #008;
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .text-content {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  .bottom-btn {
    float: right;
  }

  .section {
    margin: 1rem 0;
    position: relative;
    border: 1px solid #69be28;
    border-radius: 1rem;
    padding: 1rem 0.5rem;
  }
  .section-title {
    position: absolute;
    top: -0.6rem;
  }
}
.theme--dark {
  .section-title {
    background-color: #1e1e1e;
  }
}
.theme--light {
  .section-title {
    background-color: white;
  }
}
::v-deep .d2h-cntx {
  background-color: #fff;
}
::v-deep .d2h-emptyplaceholder {
  background-color: #f1f1f1;
}
::v-deep .d2h-code-line-prefix,
::v-deep .d2h-wrapper .hljs,
::v-deep .d2h-wrapper .hljs-section,
::v-deep .d2h-wrapper .hljs-keyword,
::v-deep .d2h-wrapper .hljs-string,
::v-deep .d2h-wrapper .hljs-literal,
::v-deep .d2h-wrapper .hljs-title,
::v-deep .d2h-wrapper .hljs-number,
::v-deep .d2h-wrapper .d2h-code-side-linenumber,
::v-deep .d2h-wrapper .d2h-code-side-line {
  color: #000;
}
::v-deep .d2h-wrapper tr {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
::v-deep .d2h-wrapper .d2h-code-side-linenumber {
  height: 18px;
}
::v-deep .d2h-diff-table {
  font-family: RobotoCondensedRegular;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f7f7f7;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header__icon {
  margin-bottom: 6px !important;
}
.expansion-panel-header__icon {
  order: 0;
}
.expansion-panel-header__header {
  order: 1;
}
</style>
